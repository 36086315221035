import React from 'react';
import { LogoCarousel } from './LogoCarousel';
import { Logo } from './Logo';
import { AnimatedBorder } from './AnimatedBorder';
import { IoEnterOutline } from "react-icons/io5";
import { RevenueCounter } from './RevenueCounter';
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import { motion } from 'framer-motion';

export function Hero() {
  return (
    <motion.section 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="relative pt-24 pb-0 px-4 overflow-hidden bg-gradient-to-b from-gray-900 via-black to-black"
    >
      <div className="max-w-7xl mx-auto">
        {/* Main content - Changed to flex column on desktop */}
        <div className="flex flex-col items-center space-y-8">
          {/* Logo */}
          <div className="w-full flex justify-center">
            <Logo size="large" />
          </div>
          
          {/* Header Content */}
          <div className="max-w-3xl space-y-6 text-center">
            <div className="flex flex-col space-y-4">
              <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl tracking-tight leading-tight max-w-[900px]">
                <span className="text-white block">
                  The Fastest Growing Alternative
                </span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white/80 to-white/60 block">
                  Education Community In Ireland
                </span>
              </h2>
            </div>
          </div>

          {/* Video Section */}
          <div className="relative w-full max-w-4xl mx-auto">
            <AnimatedBorder>
              <div className="aspect-video rounded-lg overflow-hidden relative">
                <video
                  className="w-full h-full object-cover brightness-75"
                  poster="./video_placeholder.png"
                  controls
                >
                  <source src="#" type="video/mp4" />
                </video>
              </div>
            </AnimatedBorder>
          </div>

          {/* CTA and Revenue Counter */}
          <div className="space-y-6 flex flex-col items-center">
            {/* CTA Button */}
            <div className="relative group w-full sm:w-[400px]">
              <a
                href="https://discord.gg/6KZ5Qc8rhk"
                className="relative w-full inline-flex items-center justify-center gap-2 px-8 py-4 bg-gradient-to-br from-black to-gray-900 text-lg font-bold text-white rounded-lg overflow-hidden transition-all duration-300 border border-white/10 hover:border-white/20 group-hover:scale-[1.02]"
              >
                <RiArrowRightDoubleFill className="w-5 h-5 relative z-10 transition-transform duration-500 group-hover:text-blue-400" />
                <span className="relative z-10 group-hover:text-blue-400 transition-colors duration-300">
                  JOIN FOR FREE
                </span>
                <RiArrowLeftDoubleFill className="w-5 h-5 relative z-10 transition-transform duration-500 group-hover:text-blue-400" />
                
                {/* Shine effect */}
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-full group-hover:translate-x-full duration-1000 transition-transform" />
                
                {/* Gradient border effect */}
                <div className="absolute inset-px rounded-lg bg-gradient-to-br from-transparent to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              </a>
            </div>

            {/* Revenue Counter */}
            <RevenueCounter />

          </div>
        </div>
      </div>

      {/* Logo carousel */}
      <LogoCarousel />
    </motion.section>
  );
}