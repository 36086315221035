import React from 'react';
import { Check, Zap, Crown, Users, Star } from 'lucide-react';
import { motion } from 'framer-motion';

const plans = [
  {
    icon: Users,
    name: "Community",
    price: "€0",
    period: "/month",
    description: "Perfect for those just starting their entrepreneurial journey",
    features: [
      "Free course library (€130k+ value)",
      "Basic community access",
      "Weekly newsletter",
      "Public events access",
      "Basic support"
    ],
    stats: "The path to your first €1K online"
  },
  {
    icon: Zap,
    name: "Elite Monthly",
    price: "€50",
    period: "/month",
    popular: true,
    description: "For serious entrepreneurs ready to scale their business",
    features: [
      "All premium courses",
      "Full community access",
      "Monthly masterminds",
      "1-on-1 coaching session",
      "Private networking events"
    ],
    stats: "The path to €10K per month"
  },
  {
    icon: Crown,
    name: "Elite Yearly",
    price: "€480",
    period: "/year",
    description: "Maximum value for fully committed entrepreneurs",
    features: [
      "Everything in Elite Monthly",
      "VIP mastermind retreat",
      "Priority support",
      "Early access to new content",
      "Exclusive workshops"
    ],
    stats: "Save €120/year + bonus perks"
  }
];

function PlanCard({ plan, index }) {
  return (
    <motion.div 
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { 
          opacity: 1, 
          y: 0,
          transition: {
            duration: 0.5
          }
        }
      }}
      className={`
        relative group h-full
        ${plan.popular ? 'lg:row-span-2' : ''}
      `}
    >
      {plan.popular && (
        <div className="absolute -top-3 left-1/2 -translate-x-1/2 z-10">
          <div className="relative">
            <div className="absolute inset-0 bg-blue-500 blur-md opacity-50"></div>
            <div className="relative bg-gradient-to-r from-blue-600 to-blue-400 px-4 py-1 rounded-full flex items-center gap-1">
              <Star className="w-4 h-4 text-white fill-white animate-pulse" />
              <span className="text-sm font-semibold text-white whitespace-nowrap">Most Popular</span>
            </div>
          </div>
        </div>
      )}
      
      <div className={`
        relative overflow-hidden rounded-xl bg-gradient-to-br from-black to-gray-900 p-8 border h-full flex flex-col
        ${plan.popular ? 'border-blue-500/50 shadow-lg shadow-blue-500/20' : 'border-white/10'} 
        transition-all duration-500 hover:border-white/20
        ${plan.popular ? 'lg:scale-110' : ''}
      `}>
        {/* Background Animation */}
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-full group-hover:translate-x-full duration-1000 transition-transform" />
        
        {/* Icon with Glow Effect */}
        <div className="relative mb-6">
          <div className={`absolute inset-0 blur-xl scale-150 opacity-0 group-hover:opacity-100 transition-opacity duration-500
            ${plan.popular ? 'bg-blue-500/30' : 'bg-blue-500/20'}`} />
          <plan.icon className="relative w-12 h-12 text-white transition-transform duration-500 group-hover:scale-110" />
        </div>

        {/* Pricing Header */}
        <div className="mb-6">
          <h3 className="text-xl font-bold text-white mb-2 transition-colors duration-500 group-hover:text-blue-400">
            {plan.name}
          </h3>
          <div className="flex items-baseline gap-1 mb-2">
            <span className="text-3xl font-bold text-white">{plan.price}</span>
            <span className="text-white/60">{plan.period}</span>
          </div>
          <p className="text-white/60 text-sm">
            {plan.description}
          </p>
        </div>

        {/* Features */}
        <div className="space-y-3 mb-6 flex-grow">
          {plan.features.map((feature, idx) => (
            <div key={idx} className="flex items-center gap-2 text-white/80 group-hover:text-white/90 transition-colors duration-300">
              <Check className={`w-5 h-5 ${plan.popular ? 'text-blue-400' : 'text-blue-500'}`} />
              <span className="text-sm">{feature}</span>
            </div>
          ))}
        </div>

        {/* CTA Button with href */}
        <a href="https://discord.gg/NJgGSuaby5" className="block">
          <button className={`
            w-full py-3 px-4 rounded-lg font-semibold transition-all duration-300 relative overflow-hidden group/btn
            ${plan.popular 
              ? 'bg-gradient-to-r from-blue-600 to-blue-400 hover:from-blue-700 hover:to-blue-500 text-white' 
              : 'bg-white/10 hover:bg-white/20 text-white'}
          `}>
            <span className="relative z-10">Get Started</span>
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover/btn:translate-x-full duration-1000 transition-transform" />
          </button>
        </a>

        {/* Stats */}
        <div className="pt-4 mt-4 border-t border-white/10">
          <p className="text-sm text-white/40 group-hover:text-white/60 transition-colors duration-500">
            {plan.stats}
          </p>
        </div>

        {/* Gradient Border Effect */}
        <div className="absolute inset-px rounded-xl bg-gradient-to-br from-transparent to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none" />
      </div>
    </motion.div>
  );
}

export function Pricing() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  };

  return (
    <motion.section 
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="py-20 px-4 relative overflow-hidden"
    >
      {/* Background Effects */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-gray-900/50 to-black" />
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-900/20 via-transparent to-transparent opacity-50" />
      
      <div className="max-w-7xl mx-auto relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">
            Choose Your Path
          </h2>
          <p className="text-xl text-white/60 max-w-2xl mx-auto">
            Join the exclusive community of entrepreneurs
          </p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-3 gap-6 items-center"
        >
          {plans.map((plan, index) => (
            <PlanCard key={index} plan={plan} index={index} />
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
}