import React from 'react';
import { Users, BookOpen, MessageSquare, Trophy } from 'lucide-react';
import { motion } from 'framer-motion';

const features = [
  {
    icon: Users,
    title: "Elite Network",
    description: "Connect with successful entrepreneurs who share your drive and ambition.",
    details: [
      "Access to verified 7-8 figure entrepreneurs",
      "Direct networking opportunities",
      "Exclusive mastermind groups",
      "Industry-specific connections"
    ]
  },
  {
    icon: BookOpen,
    title: "Expert Training",
    description: "Access premium courses and workshops led by industry leaders.",
    details: [
      "Weekly live training sessions",
      "On-demand course library",
      "Expert-led workshops",
      "Implementation guides"
    ]
  },
  {
    icon: MessageSquare,
    title: "Private Community",
    description: "Engage in high-level discussions and get real-time feedback.",
    details: [
      "24/7 community support",
      "Private discussion forums",
      "Real-time problem solving",
      "Accountability groups"
    ]
  },
  {
    icon: Trophy,
    title: "Exclusive Events",
    description: "Attend VIP masterminds and networking events.",
    details: [
      "Quarterly virtual summits",
      "Annual in-person retreat",
      "Regional meetups",
      "VIP speaker sessions"
    ]
  }
];

function FeatureCard({ feature, index }) {
  return (
    <motion.div 
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { 
          opacity: 1, 
          y: 0,
          transition: {
            duration: 0.5
          }
        }
      }}
      className="relative group h-full"
    >
      <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-black to-gray-900 p-8 border border-white/10 transition-all duration-500 hover:border-white/20 h-full flex flex-col">
        {/* Background Animation */}
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-full group-hover:translate-x-full duration-1000 transition-transform" />
        
        {/* Icon with Glow Effect */}
        <div className="relative mb-6">
          <div className="absolute inset-0 blur-xl scale-150 opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-blue-500/20" />
          <feature.icon className="relative w-12 h-12 text-white transition-transform duration-500 group-hover:scale-110" />
        </div>

        {/* Content */}
        <div className="flex-grow">
          <h3 className="text-xl font-bold text-white mb-3 transition-colors duration-500 group-hover:text-blue-400">
            {feature.title}
          </h3>
          <p className="text-white/60 mb-6 transition-opacity duration-500 group-hover:text-white/80">
            {feature.description}
          </p>
          
          {/* Details */}
          <ul className="space-y-3">
            {feature.details.map((detail, idx) => (
              <li 
                key={idx} 
                className="flex items-center gap-2 text-white/70 group-hover:text-white/90 transition-colors duration-300"
              >
                <div className="w-1.5 h-1.5 rounded-full bg-blue-500/50 group-hover:bg-blue-400 transition-colors duration-300" />
                <span className="text-sm">{detail}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Gradient Border Effect */}
        <div className="absolute inset-px rounded-xl bg-gradient-to-br from-transparent to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none" />
      </div>
    </motion.div>
  );
}

export function Features() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="py-20 px-4"
    >
      <div className="max-w-7xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-white text-center mb-16"
        >
          Inside The Community
        </motion.h2>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
        >
          {features.map((feature, index) => (
            <FeatureCard key={index} feature={feature} index={index} />
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
}