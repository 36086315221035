import React from 'react';
import { motion } from 'framer-motion';

const logos = [
  {
    name: 'BBC',
    src: './bbc.png'
  },
  {
    name: 'RTE',
    src: './rte.png'
  },
  {
    name: 'Virgin Media News',
    src: './virgin.png'
  },
  {
    name: 'The Sunday Times',
    src: './sun.png'
  }
];

export function LogoCarousel() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: 0.3 }}
    >
      <div className="bg-gradient-to-b from-black via-black to-black">
        <div className="w-full bg-black/50 backdrop-blur-sm py-8 mt-16">
          <p className="text-center text-white/60 text-sm uppercase tracking-wider mb-6">
            As Seen On
          </p>
          <div className="max-w-4xl mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-items-center">
              {logos.map((logo, index) => (
                <div
                  key={index}
                  className="grayscale opacity-50 hover:grayscale-0 hover:opacity-100 transition-all duration-300 animate-fadeIn"
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  <img 
                    src={logo.src} 
                    alt={logo.name} 
                    className="h-20 object-contain" 
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}