import React, { useMemo } from 'react';
import { motion } from 'framer-motion';

const testimonials = [
  { imageUrl: "./test1.png", alt: "Discord Message 1" },
  { imageUrl: "./test2.png", alt: "Discord Message 2" },
  { imageUrl: "./test3.png", alt: "Discord Message 3" },
  { imageUrl: "./test4.png", alt: "Discord Message 4" },
  { imageUrl: "./test5.png", alt: "Discord Message 5" },
  { imageUrl: "./test6.png", alt: "Discord Message 6" },
  { imageUrl: "./test7.png", alt: "Discord Message 7" },
  { imageUrl: "./test8.png", alt: "Discord Message 8" },
  { imageUrl: "./test9.png", alt: "Discord Message 9" },
  { imageUrl: "./test10.png", alt: "Discord Message 10" }
];

export function DiscordTestimonials() {
  const randomizedRow1 = useMemo(() => 
    [...testimonials].sort(() => Math.random() - 0.5), []
  );
  
  const randomizedRow2 = useMemo(() => 
    [...testimonials].sort(() => Math.random() - 0.5), []
  );

  // Create arrays with 5 copies of the randomized testimonials to ensure full visibility
  const row1Items = [
    ...randomizedRow1, 
    ...randomizedRow1, 
    ...randomizedRow1, 
    ...randomizedRow1, 
    ...randomizedRow1
  ];

  const row2Items = [
    ...randomizedRow2, 
    ...randomizedRow2, 
    ...randomizedRow2, 
    ...randomizedRow2, 
    ...randomizedRow2
  ];

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="py-20"
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="max-w-7xl mx-auto px-4 mb-12"
      >
        <h2 className="text-4xl font-bold text-white text-center mb-4">
          What Our Members Are Saying
        </h2>
        <p className="text-white/60 text-center text-lg">
          Real results from those who have taken action
        </p>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.3 }}
        className="max-w-7xl mx-auto px-4 relative"
      >
        <div className="relative">
          {/* Left and right gradients */}
          <div className="absolute inset-y-0 left-0 w-16 md:w-32 bg-gradient-to-r from-black to-transparent z-10" />
          <div className="absolute inset-y-0 right-0 w-16 md:w-32 bg-gradient-to-l from-black to-transparent z-10" />
          
          {/* Row 1 - Left to Right */}
          <div className="mb-4 overflow-hidden">
            <div className="flex gap-2 md:gap-4 animate-scroll-fast whitespace-nowrap will-change-transform">
              {row1Items.map((testimonial, index) => (
                <div
                  key={`row1-${index}`}
                  className="flex-shrink-0 w-[350px] md:w-[600px] h-[175px] md:h-[300px] rounded-lg overflow-hidden inline-block"
                >
                  <img
                    src={testimonial.imageUrl}
                    alt={testimonial.alt}
                    className="w-full h-full object-cover"
                    loading="eager"
                  />
                </div>
              ))}
            </div>
          </div>
          
          {/* Row 2 - Right to Left */}
          <div className="mb-4 overflow-hidden">
            <div 
              className="flex gap-2 md:gap-4 animate-scroll-fast-reverse whitespace-nowrap will-change-transform"
            >
              {row2Items.map((testimonial, index) => (
                <div
                  key={`row2-${index}`}
                  className="flex-shrink-0 w-[350px] md:w-[600px] h-[175px] md:h-[300px] rounded-lg overflow-hidden inline-block"
                >
                  <img
                    src={testimonial.imageUrl}
                    alt={testimonial.alt}
                    className="w-full h-full object-cover"
                    loading="eager"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
}