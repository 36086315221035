import React, { useRef } from 'react';
import { Tooltip } from './Tooltip';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';

const courses = [
  {
    title: "Anthropology of Consumerism",
    instructor: "John Doe",
    image: "./dropship.png",
    description: "Understand the psychology behind consumer behavior and leverage it to build successful products and marketing campaigns.",
    modules: [
      { title: "Consumer Psychology Fundamentals", value: "€2,500 Value" },
      { title: "Market Research Techniques", value: "€1,500 Value" },
      { title: "Behavioral Economics", value: "€2,000 Value" },
      { title: "Digital Consumer Trends", value: "€1,800 Value" },
      { title: "Case Studies & Analysis", value: "€1,200 Value" }
    ]
  },
  {
    title: "Create. Multiply. Preserve.",
    instructor: "Brian Tracy",
    image: "./rug.png",
    description: "Master the art of wealth creation, multiplication, and preservation with proven strategies from industry expert Brian Tracy.",
    modules: [
      { title: "Wealth Creation Principles", value: "€3,000 Value" },
      { title: "Investment Strategies", value: "€2,500 Value" },
      { title: "Asset Protection", value: "€2,000 Value" },
      { title: "Tax Optimization", value: "€1,500 Value" },
      { title: "Estate Planning", value: "€2,000 Value" }
    ]
  },
  {
    title: "Amazon FBA Playbook",
    instructor: "Alex Chen",
    image: "./dropship.png",
    description: "A comprehensive guide to building a successful Amazon FBA business from scratch to scale.",
    modules: [
      { title: "Product Research Mastery", value: "€2,000 Value" },
      { title: "Supplier Negotiations", value: "€1,500 Value" },
      { title: "Listing Optimization", value: "€1,800 Value" },
      { title: "PPC Advertising", value: "€2,200 Value" },
      { title: "Inventory Management", value: "€1,500 Value" }
    ]
  },
  {
    title: "Scaling Past 8 Figures",
    instructor: "Mike Zhang",
    image: "./rug.png",
    description: "Advanced strategies and systems for scaling your business beyond the 8-figure mark.",
    modules: [
      { title: "Systems & Automation", value: "€3,500 Value" },
      { title: "Team Building & Culture", value: "€2,500 Value" },
      { title: "Strategic Partnerships", value: "€2,000 Value" },
      { title: "Financial Modeling", value: "€2,500 Value" },
      { title: "Exit Strategy Planning", value: "€3,000 Value" }
    ]
  }
];

export function Courses() {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 420; // Width of one card + gap
      const currentScroll = scrollContainerRef.current.scrollLeft;
      const newScroll = direction === 'left' 
        ? currentScroll - scrollAmount 
        : currentScroll + scrollAmount;
      
      scrollContainerRef.current.scrollTo({
        left: newScroll,
        behavior: 'smooth'
      });
    }
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="py-20 px-4"
    >
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">
            Inside The Academy
          </h2>
          <p className="text-xl text-white/80 max-w-3xl mx-auto">
            Gain access to over 200 hours of courses and exclusive series
            brought to you by various experts from around Ireland.
          </p>
        </motion.div>
        
        <div className="relative">
          <motion.div 
            ref={scrollContainerRef}
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="flex gap-6 overflow-x-auto pb-8 snap-x snap-mandatory scrollbar-hide"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {courses.map((course, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="min-w-[400px] snap-start group cursor-pointer"
              >
                <Tooltip
                  content={
                    <div className="w-full">
                      <h4 className="text-lg font-semibold text-white mb-2">{course.title}</h4>
                      <p className="text-sm text-white/60 mb-4">with {course.instructor}</p>
                      <div className="space-y-2">
                        {course.modules.map((module, idx) => (
                          <div key={idx} className="flex justify-between items-center text-sm">
                            <span className="text-white/80">{module.title}</span>
                            <span className="text-green-500">{module.value}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                >
                  <div className="relative aspect-[4/5] rounded-lg overflow-hidden bg-gray-900">
                    <img
                      src={course.image}
                      alt={course.title}
                      className="w-full h-full object-cover opacity-75 group-hover:opacity-40 transition-opacity"
                    />
                    <div className="absolute inset-0 bg-black/40 group-hover:bg-black/60 transition-colors" />
                  </div>
                </Tooltip>
              </motion.div>
            ))}
          </motion.div>
          
          <motion.button 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
            onClick={() => scroll('left')}
            className="absolute top-1/2 -left-4 -translate-y-1/2 w-8 h-8 bg-white rounded-full flex items-center justify-center text-black hover:bg-gray-200 transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </motion.button>
          <motion.button 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
            onClick={() => scroll('right')}
            className="absolute top-1/2 -right-4 -translate-y-1/2 w-8 h-8 bg-white rounded-full flex items-center justify-center text-black hover:bg-gray-200 transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </motion.button>
        </div>
      </div>
    </motion.section>
  );
}