import React from 'react';
import { Check, X } from 'lucide-react';
import { motion } from 'framer-motion';

const comparisonData = {
  elite: {
    title: "WITH ONE PERCENT",
    color: "text-green-800",
    items: [
      "Success in 15-20 minutes daily.",
      "Proven frameworks.",
      "Mentorship and supportive community.",
      "Low-risk, high-reward systems.",
      "Easy-to-use resources."
    ]
  },
  regular: {
    title: "WITHOUT ONE PERCENT",
    color: "text-red-800",
    items: [
      "Over 40 hours of weekly work.",
      "Years of risky trial and error.",
      "No guidance, entirely on your own.",
      "Significant risk.",
      "Requires complex setups."
    ]
  }
};

export function Comparison() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="py-20 px-4"
    >
      <div className="max-w-7xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-white text-center mb-16"
        >
          The Elite Advantage
        </motion.h2>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto"
        >
          {Object.entries(comparisonData).map(([key, data]) => (
            <motion.div
              key={key}
              variants={itemVariants}
              className="relative group h-full"
            >
              <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-black to-gray-900 p-8 border border-white/10 transition-all duration-500 hover:border-white/20 h-full flex flex-col">
                {/* Background Animation */}
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-full group-hover:translate-x-full duration-1000 transition-transform" />

                {/* Title */}
                <h3 className={`text-xl font-bold ${data.color} mb-8 text-center`}>
                  {data.title}
                </h3>

                {/* Items */}
                <div className="space-y-6 flex-grow">
                  {data.items.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-start gap-3 animate-slideIn"
                      style={{ animationDelay: `${index * 0.1}s` }}
                    >
                      {key === 'elite' ? (
                        <Check className="w-6 h-6 text-green-800 flex-shrink-0 mt-0.5" />
                      ) : (
                        <X className="w-6 h-6 text-red-800 flex-shrink-0 mt-0.5" />
                      )}
                      <p className="text-white/80 text-lg leading-tight">
                        {item}
                      </p>
                    </div>
                  ))}
                </div>

                {/* Gradient Border Effect */}
                <div className="absolute inset-px rounded-xl bg-gradient-to-br from-transparent to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none" />
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
}