import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const faqs = [
  {
    question: "What is One Percent?",
    answer: "One Percent is a private, Irish-based community designed for individuals seeking to break free from traditional education and employment systems. We provide access to alternative methods of learning, such as cryptocurrency trading, social media marketing, and other wealth-building strategies.\nMembers gain step-by-step frameworks, mentorship, and tools to achieve financial independence with minimal time commitment. By joining, you’ll unlock proven systems, exclusive resources, and a network of like-minded high-performers.\nOur upgraded Elite tier offers even more, including advanced courses, AI-driven tools, and direct access to the community founders. One Percent is your path to freedom and success."
  },
  {
    question: "What do I need to become a member?",
    answer: "Joining One Percent is simple. All you need is a Discord account and the willingness to take the first step toward your goals. The community is hosted entirely on Discord, making it easy to connect, learn, and grow.\nTo get started, just click the Join button in the navigation bar and follow the instructions.\nIt’s free to join, and you’ll gain immediate access to our vibrant community and resources."
  },
  {
    question: "Do I need to have any experience to join?",
    answer: "No experience is required to join One Percent.\nOur community is designed for both beginners and seasoned professionals. With step-by-step guides, proven frameworks, and dedicated mentorship, we ensure you have everything you need to succeed, regardless of your starting point.\nWhether you’re exploring cryptocurrencies, social media marketing, or other strategies, our resources are tailored to help you learn, grow, and achieve results. All you need is a willingness to take action and follow the systems provided."
  },
  {
    question: "How do I know if this is for me?",
    answer: "If you’re ready to break free from conventional systems and take control of your future, One Percent is for you.\nJoining the community is completely free, making it easy to explore alternative strategies like cryptocurrency trading, social media marketing, or flipping opportunities.\nWhether you're a beginner or looking to level up your skills, our step-by-step systems, mentorship, and resources are designed to deliver results. If you value growth, financial independence, and a supportive network, this is your chance to start without any upfront commitment."
  },
  {
    question: "Will new courses be added?",
    answer: "Yes, we regularly update and expand our library of courses to ensure members have access to the latest strategies and opportunities.\nAs industries evolve, we add new content on emerging trends like advanced cryptocurrency techniques, cutting-edge marketing strategies, and other wealth-building systems.\nElite members gain exclusive first access to these updates, ensuring they stay ahead of the curve. Our goal is to provide you with everything you need to succeed, now and in the future."
  },
  {
    question: "Is there an affiliate program?",
    answer: "Yes! One Percent offers an affiliate program that rewards you for sharing the community. You’ll earn €20 for every person who joins through your invite link and upgrades to the Elite tier.\nThere’s no limit to how much you can earn: the more people you refer, the more you make. It’s a simple way to share value with others while building an additional income stream for yourself."
  },
  {
    question: "I'm busy with other commitments, will this still work for me?",
    answer: "Absolutely!\nOne Percent is designed to fit into busy schedules, making it ideal for students, professionals, or anyone balancing other priorities. Our resources and mentorship help you build skills and create opportunities at your own pace, no matter your starting point."
  }
];

export function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <section className="py-20 px-4">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold text-white text-center mb-16">
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="border border-white/10 rounded-lg overflow-hidden"
            >
              <button
                className="w-full px-6 py-4 flex items-center justify-between text-left hover:bg-white/5 transition-colors"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <h3 className="text-xl font-semibold text-white">
                  {faq.question}
                </h3>
                <ChevronDown 
                  className={`w-5 h-5 text-white/60 transition-transform ${
                    openIndex === index ? 'rotate-180' : ''
                  }`} 
                />
              </button>
              {openIndex === index && (
                <div className="px-6 py-4 text-white/60 leading-relaxed border-t border-white/10">
                  {faq.answer.split('\n').map((paragraph, i) => (
                    <React.Fragment key={i}>
                      {paragraph}
                      {i < faq.answer.split('\n').length - 1 && (
                        <><br /><br /></>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}