import React from 'react';
import { Target, Zap, Shield, Star } from 'lucide-react';
import { motion } from 'framer-motion';

const features = [
  {
    icon: Target,
    title: "Focused Growth",
    description: "Access proven strategies and frameworks that help you create a 7-figure net worth.",
    stats: "97% of elite members see results within 90 days"
  },
  {
    icon: Zap,
    title: "Rapid Implementation",
    description: "Unlock your potential with proven cash-generating strategies.",
    stats: "2.5x faster implementation rate"
  },
  {
    icon: Shield,
    title: "Private Network",
    description: "Connect with like-minded entrepreneurs who match your ambition.",
    stats: "Verified 7-8 figure entrepreneurs"
  },
  {
    icon: Star,
    title: "Premium Courses",
    description: "Unlimited access to an entire library of life-changing courses & resources.",
    stats: "24/7 dedicated support team"
  }
];

function FeatureCard({ feature, index }) {
  return (
    <motion.div 
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { 
          opacity: 1, 
          y: 0,
          transition: {
            duration: 0.5
          }
        }
      }}
      className="relative group h-full"
    >
      <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-black to-gray-900 p-8 border border-white/10 transition-all duration-500 hover:border-white/20 h-full flex flex-col">
        {/* Background Animation */}
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-full group-hover:translate-x-full duration-1000 transition-transform" />
        
        {/* Icon with Glow Effect */}
        <div className="relative mb-6">
          <div className="absolute inset-0 blur-xl scale-150 opacity-0 group-hover:opacity-100 transition-opacity duration-500 bg-blue-500/20" />
          <feature.icon className="relative w-12 h-12 text-white transition-transform duration-500 group-hover:scale-110" />
        </div>

        {/* Content */}
        <div className="flex-grow">
          <h3 className="text-xl font-bold text-white mb-3 transition-colors duration-500 group-hover:text-blue-400">
            {feature.title}
          </h3>
          <p className="text-white/60 mb-6 transition-opacity duration-500 group-hover:text-white/80">
            {feature.description}
          </p>
        </div>
        
        {/* Stats */}
        <div className="pt-4 border-t border-white/10">
          <p className="text-sm text-white/40 group-hover:text-white/60 transition-colors duration-500">
            {feature.stats}
          </p>
        </div>

        {/* Gradient Border Effect */}
        <div className="absolute inset-px rounded-xl bg-gradient-to-br from-transparent to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500 pointer-events-none" />
      </div>
    </motion.div>
  );
}

export function WhyElite() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      }
    }
  };

  return (
    <motion.section 
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="py-20 px-4 relative overflow-hidden"
    >
      {/* Background Effects */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-gray-900/50 to-black" />
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-blue-900/20 via-transparent to-transparent opacity-50" />
      
      <div className="max-w-7xl mx-auto relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">
            Why Choose Elite?
          </h2>
          <p className="text-xl text-white/60 max-w-2xl mx-auto">
            Unlock the path they don't want you to see
          </p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
        >
          {features.map((feature, index) => (
            <FeatureCard key={index} feature={feature} index={index} />
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
}